import { SERIES } from "../data/books";

const REPLACE = [" ", ",", "-", "%", "&", "/", "+", "$", "§", "{", "}", "(", ")", "[", "]", "!", "?"];

const removeStrings = (remove: string[], from: string): string => {
    remove.forEach(r => {
        from = from.replaceAll(r, "");
    });
    return from;
}

export const getURLSafeString = (text: string | undefined): string => {
    if (text) {
        const preparedText = removeStrings(REPLACE, text)
            .replaceAll("ä", "ae")
            .replaceAll("ö", "oe")
            .replaceAll("ü", "ue")
            .replaceAll("ü", "ue")
            .replaceAll("ß", "ss");
        return encodeURIComponent(preparedText).toLowerCase();
    } else return "";
}

export const getRoutePathForSeries = (series?: string, subSeries?: string) => {
    if (series) {
        switch (series) {
            case SERIES.RUSH_INN: {
                switch (subSeries) {
                    case "Stadtgeflüster":
                        return "/books/rushInn/sg";
                    case "ICU - I see you":
                        return "/books/rushInn/icu";
                    case "Limbus":
                        return "/books/rushInn/limbus";
                    case "Einzelbände":
                        return "/books/rushInn/single";
                    default:
                        return "/books/rushInn"
                }
            }
            case SERIES.SCHWARZES_HERZ:
                return "/books/sw";
            case SERIES.BELLETRISTICA:
                return "/books/belle";
            case SERIES.EINZELBAND:
                return "/books/single";
            default:
                return "/books";
        }
    } else return "/books";
}

