import { Book, SERIES, STATUS } from "../../data/books";
import React, { useEffect, useState } from "react";
import PortfolioItem from "./PortfolioItem";
import FilterSection from "./Filter";
import { defaultBcPath } from "../../utils/config";
import { BookOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { getRoutePathForSeries } from "../../utils/stringUtils";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import { Breadcrumb } from "antd";

interface Props {
    books: Book[];
    preselectedSeries?: string;
    preselectedSubSeries?: string;
}

const Portfolio: React.FC<Props> = (props) => {
    const { books, preselectedSeries, preselectedSubSeries } = props;
    const [selectedSeries, setSelectedSeries] = useState<string | null>(preselectedSeries ?? null);
    const [selectedSubSeries, setSelectedSubSeries] = useState<string | null>(preselectedSubSeries ?? null);
    const [selectedState, setSelectedState] = useState<STATUS | null>(null);
    const [localPath, setLocalPath] = useState<BreadcrumbItemType[]>();

    const seriesOptions = Object.values(SERIES);
    const subSeriesOptions = selectedSeries ? Array.from(new Set(books.filter(book => book.series === selectedSeries).map(book => book.subSeries ?? ""))) : [];
    const statusOptions = Object.values(STATUS);

    useEffect(() => {
        let path = defaultBcPath.concat(
            {
                title: ( <Link to={"/books"}><BookOutlined/> Bücher</Link> )
            }
        )
        if (preselectedSeries) {
            setSelectedSeries(preselectedSeries);
            path = path.concat(
                {
                    title: ( <Link to={getRoutePathForSeries(preselectedSeries)}> {preselectedSeries}</Link> )
                }
            )
        }
        if (preselectedSubSeries) {
            setSelectedSubSeries(preselectedSubSeries)
            path = path.concat(
                {
                    title: ( <Link
                        to={getRoutePathForSeries(preselectedSeries, preselectedSubSeries)}> {preselectedSubSeries}</Link> )
                }
            )
        }
        setLocalPath(path);

    }, [preselectedSeries, preselectedSubSeries])

    const filteredBooks = books.filter(book => {
        if (selectedSeries && book.series !== selectedSeries) {
            return false;
        }
        if (selectedSubSeries && book.subSeries !== selectedSubSeries) {
            return false;
        }
        if (selectedState && book.state !== selectedState) {
            return false;
        }
        return true;
    });

    const clearSeriesFilter = () => {
        setSelectedSeries(null);
        setSelectedSubSeries(null);
    };
    const clearStateFilter = () => {
        setSelectedState(null);
    }

    return (
        <div className="portfolio">
            <Breadcrumb items={localPath}/>
            {/*<h1>Bücher</h1>*/}
            <FilterSection
                seriesOptions={seriesOptions}
                subSeriesOptions={subSeriesOptions}
                selectedSeries={selectedSeries}
                selectedSubSeries={selectedSubSeries}
                setSelectedSeries={setSelectedSeries}
                setSelectedSubSeries={setSelectedSubSeries}
                clearSeriesFilter={clearSeriesFilter}
                statusOptions={statusOptions}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                clearStateFilter={clearStateFilter}
            />
            <div id={"itemNumber"} style={{ textAlign: "center" }}>Anzahl Bücher: {filteredBooks.length}</div>
            <div id={"portfolioDisplay"}>
                {filteredBooks.map((book, index) => (
                    <PortfolioItem book={book} index={index} key={index}/>
                ))}
            </div>
        </div>
    );
};

export default Portfolio