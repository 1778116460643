import React from "react";
import { SERIES, STATUS } from "../../data/books";
import "../../theme/Portfolio.scss";
import { SeriesFilter, StatusFilter } from "./FilterComponents";
import { Flex } from "antd";

interface FilterSectionProps {
    seriesOptions: SERIES[] | undefined;
    subSeriesOptions: string[];
    selectedSeries: string | null;
    selectedSubSeries: string | null;
    setSelectedSeries: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedSubSeries: React.Dispatch<React.SetStateAction<string | null>>;
    clearSeriesFilter: () => void;
    statusOptions: STATUS[];
    selectedState: STATUS | null;
    setSelectedState: React.Dispatch<React.SetStateAction<STATUS | null>>;
    clearStateFilter: () => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
                                                         seriesOptions,
                                                         subSeriesOptions,
                                                         selectedSeries,
                                                         selectedSubSeries,
                                                         setSelectedSeries,
                                                         setSelectedSubSeries,
                                                         clearSeriesFilter,
                                                         statusOptions,
                                                         selectedState,
                                                         setSelectedState,
                                                         clearStateFilter
                                                     }) => {

    return (
        <div className={"portfolioContainer"}>
            <Flex className={"filterFlex"}>
                <StatusFilter
                    statusOptions={statusOptions}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    clearStateFilter={clearStateFilter}
                    setSelectedSeries={setSelectedSeries}
                    setSelectedSubSeries={setSelectedSubSeries}/>
                <SeriesFilter
                    seriesOptions={seriesOptions}
                    subSeriesOptions={subSeriesOptions}
                    selectedSeries={selectedSeries}
                    selectedSubSeries={selectedSubSeries}
                    setSelectedSeries={setSelectedSeries}
                    setSelectedSubSeries={setSelectedSubSeries}
                    clearSeriesFilter={clearSeriesFilter}
                />
            </Flex>
        </div>
    );
};

export default FilterSection;
