import React from 'react';

export enum SERIES {
    RUSH_INN = "Rush-Inn",
    SCHWARZES_HERZ = "Schwarzes Herz",
    EINZELBAND = "Einzelband",
    BELLETRISTICA = "Belletristica"
}

export enum BOOK_TYPE {
    ROMAN = "Roman",
    KURZGESCHICHTE = "Kurzgeschichte",
    NOVELLE = "Novelle",
    POESIE = "Poesie",
    MÄRCHEN = "Märchen"
}

export enum GENRE {
    LOVESTORY = "Lovestory",
    DRAMA = "Drama",
    FAMILIE = "Familie",
    FANTASY = "Fantasy",
    KRIMI = "Krimi",
    SCIENCE_FICTION = "Science Fiction"
}

export enum STATUS {
    PLANNED = "geplant",
    STARTED = "begonnen",
    INTERRUPTED = "unterbrochen",
    FINISHED = "beendet",
    PUBLISHED = "veröffentlicht"
}

export const books: Book[] = [
    {
        title: "Vergeben, nie Vergessen",
        series: SERIES.RUSH_INN,
        subSeries: "Einzelbände",
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.DRAMA],
        tags: ["MxM", "Düster"],
        cover: "vnv-2_o.jpg",
        content: "Es ist in allen Nachrichten. Zig Tote, man spricht von 10, 20 vielleicht auch 30, unzählige Verletzte heißt es. Wovon die Nachrichten nicht sprechen sind diejenigen, denen scheinbar nichts geschehen ist. Doch jede Tat hat Folgen und nicht immer kann man sie so leicht verarbeiten, wie es von einem erwartet wird. Nachdem er überlebt hat, muss ein junger Mann in sein altes Leben zurückfinden. Was er findet ist jedoch nur Leere und der einzige, der diese Leere zu füllen scheint ist ein Junge, der noch mehr verloren hat als er.",
        state: STATUS.FINISHED,
        length: "ca. 68.000 Wörter",
        additionalInfo: "Bonusstory:\n" +
            "\n" +
            "\"Ein Moment der Ewigkeit\" - spielt zeitlich zwischen dem 19. und dem 20. Kapitel; das Ende der Geschichte wird nicht gespoilert, kann also auch unabhängig gelesen werden."
    },
    {
        title: "Unter Blättern",
        series: SERIES.RUSH_INN,
        subSeries: "Einzelbände",
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.FAMILIE, GENRE.DRAMA],
        tags: ["MxM"],
        content: "Eigentlich hat Nate keinen Grund sich zu beklagen. Er hat einen Job, eine Wohnung, eine Familie. Auch wenn letztere mitunter durchaus nervig sein kann. Trotzdem ist er nicht wirklich zufrieden mit seinem Leben. Dann trifft er jemanden, den es offenbar deutlich härter getroffen hat. ",
        state: STATUS.FINISHED,
        length: "6.000 Wörter",
        cover: "jago0-33_o.jpg"
    },
    {
        title: "Blindes Vertrauen",
        series: SERIES.RUSH_INN,
        subSeries: "ICU - I see you",
        subSeriesNo: 1,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.DRAMA],
        tags: ["MxM"],
        cover: "icu1-3_o.jpg",
        content: "Schule beendet, den Zusammenzug mit seinem Freund vor Augen - Pauls Leben könnte gerade nicht besser laufen.  Dann passiert ein Unfall und plötzlich verwandelt sich sein eigentlich perfektes Leben ins Gegenteil. Erst als er Monate später den lebenslustigen Alan trifft, fängt er langsam an, wieder einen Sinn in seiner Existenz zu sehen. Allerdings ahnt Paul nicht, dass Alan seine ganz eigenen Baustellen hat.",
        state: STATUS.FINISHED,
        length: "ca. 75.000 Wörter",
        additionalInfo: "Bonusstory \"In den Augen der Anderen\" spielt zeitlich etwa ein Jahr vorher, während Alan in München ist für seine Therapeuthenausbildung."
    },
    {
        title: "Alles auf Sieg",
        series: SERIES.RUSH_INN,
        subSeries: "ICU - I see you",
        subSeriesNo: 2,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.FAMILIE],
        tags: ["MxM", "Romance"],
        cover: "icu2-4_o.jpg",
        content: (
            <div>
                <p>Als Schneider hat sich Richard 'Ricky' Hansen nicht gerade einen sonderlich 'männlichen' Beruf
                    ausgesucht. Dass er diesen auch noch ausgerechnet in dem Brautmodengeschäft seiner Tante ausübt
                    macht die Sache nicht unbedingt besser. Aber er liebt diesen Job über alles und - was viel wichtiger
                    ist - er ist verdammt gut darin. So ist es auch nicht verwunderlich, dass von überall her junge
                    Damen zu ihm kommen um das perfekte Brautkleid auf den Leib geschnitten zu bekommen.</p>
                <p>Als eine neue Kundin mit diesem viel zu gut aussehenden Typen André an ihrer Seite hereinschneit,
                    scheint alles wie immer. Doch dann trifft Ricky eben jenen André noch am gleichen Abend in einer
                    stadtbekannten Schwulenkneipe wieder. Und der macht ihn auch noch an. Das geht Ricky zu weit. Sauer
                    über so viel Dreistigkeit und Unehrlichkeit stürmt er davon.</p>
                <p>Doch wie heißt es so schön? Erstes kommt es anders, und zweitens als man denkt.</p>
            </div>
        ),
        state: STATUS.INTERRUPTED,
        length: "vorr. ca. 100.000-150.000 Wörter",
    },
    {
        title: "Frühlingsgefühle",
        series: SERIES.RUSH_INN,
        subSeries: "ICU - I see you",
        subSeriesNo: 2,
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.LOVESTORY],
        tags: ["MxM", "Romance", "Bonus"],
        cover: "icu2-2-34_o.jpg",
        content: "Ein Frühlingstag im Park. Mit verträumten Rückblicken, schönen Ansichten und verlockenden Aussichten.",
        additionalInfo: "Eine kleine Kurzgeschichte, die zwischen Kapitel 12 und 13 von \"Alles auf Sieg\" spielt.",
        state: STATUS.FINISHED,
        length: "ca. 2600 Wörter",
    },
    {
        title: "Warum?",
        series: SERIES.RUSH_INN,
        subSeries: "Stadtgeflüster",
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.LOVESTORY],
        tags: ["MxM", "Young Adult", "Erste Liebe", "Bonus"],
        cover: "stadtgefluester0-32_o.jpg",
        content: "Warum tat er sich das an? Warum saß er hier? Warum ausgerechnet der, du dummes Herz?",
        additionalInfo: "Eine kleine Kurzgeschiche über Lance und Leon in ihrer Jugend. Sie spielt ca. 23 Jahre vor \"Wo die Liebe hinfällt\".",
        state: STATUS.FINISHED,
        length: "ca. 3600 Wörter",
    },
    {
        title: "Wo die Liebe hinfällt",
        series: SERIES.RUSH_INN,
        subSeries: "Stadtgeflüster",
        subSeriesNo: 1,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.KRIMI],
        tags: ["MxM", "New Adult", "Romance"],
        cover: "stadtgefluester1-23_o.jpg",
        content: "Mit 24 noch in Ausbildung und trotzdem war Frederik immer recht zufrieden mit seinem Leben. Doch in den letzten Monaten läuft alles schief: Sein Freund geht fremd, die Kollegen meiden ihn, seit sie wissen, das er schwul ist und jetzt sickern Gerüchte durch, dass die Firma pleite wäre. Als ihn ausgerechnet der gut aussehende Lance anspricht, wagt er nicht, auf ein Ende seiner Pechsträhne zu hoffen. Aber dieser Mann ist in jeder Hinsicht anders und schnell weiß Frederik nicht mehr, was er denken oder fühlen soll. Dazu ein Kollege, der sich merkwürdig benimmt sowie Projekte, die jeder will, aber niemand haben darf. Was kann eigentlich noch alles schiefgehen?\n",
        state: STATUS.FINISHED,
        length: "ca. 167.000 Wörter",
    },
    {
        title: "Was Vertrauen heißt",
        series: SERIES.RUSH_INN,
        subSeries: "Stadtgeflüster",
        subSeriesNo: 2,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.KRIMI],
        tags: ["MxM", "New Adult", "Romance"],
        cover: "stadtgefluester2-6_o.jpg",
        content: "Inzwischen sind Lance und Frederik seit vier Monaten zusammen und für beide läuft es blendend. Während Lance auf Geschäftsreise ist, beschließt Frederik dem Drängen von Kilian nachzugeben, und geht mit dem zusammen aus. Was ursprünglich die Beziehung zu dem Jungen lediglich verbessern sollte, stellt jedoch schnell alle Beteiligten auf eine harte Probe.",
        state: STATUS.PLANNED,
        length: "?",
    },
    {
        title: "Wer Verantwortung trägt",
        series: SERIES.RUSH_INN,
        subSeries: "Stadtgeflüster",
        subSeriesNo: 3,
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.DRAMA],
        tags: ["Familie"],
        cover: "stadtgefluester3-7_o.jpg",
        content: (
            <div>
                <div>
                    <h3>Geh Weg!</h3>
                    <p>Ein Wiedersehen im Regen, eine Einkaufstüte und ein blödes Ziehen im Bauch.<br/>
                        Es ist kurz vor Weihnachten und der 11-Jährigen Torin bekommt unerwartet Gesellschaft.</p>
                    <p>Länge: ~1900 Wörter, 1 Kapitel<br/>
                        (Oneshot - fertig)</p>
                </div>
                <div>
                    <h3>Lass mich in Ruhe!</h3>
                    <p>Weihnachten steht schon wieder vor der Tür und der inzwischen 12-Jährige Torin kämpft mit den
                        üblichen Problemen seiner Familie: Kein Geld und drei nervige kleine Brüder, für die niemand
                        außer ihm die Verantwortung übernimmt und ein Vater, den das alles so gar nicht interessiert -
                        dazu ein nervender Mario. Alle Jahre wieder bekommt da eine ganz neue Bedeutung.</p>
                    <p>Länge: ~26k Wörter, 13 Kapitel<br/>
                        (Novelle - fertig)
                    </p>
                </div>
                <div>
                    <h3>Verpiss dich!</h3>
                    <p>Im September fallen die ersten Blätter - und mitunter auch andere Dinge. Torin hat eine schwere
                        Entscheidung zu treffen. Für sich und seine Brüder. </p>
                    <p>(in Planung)</p>
                </div>
                <div>
                    <h3>Fass mich nicht an!</h3>
                    <p>In Torins Familie war ja schon immer alles knapp: Liebe, Geld, Essen - bei dem einen oder anderen
                        auch der Verstand. Der Tod seines Vaters hat die Sache nicht leichter gemacht. Für keinen der
                        vier Brüder. Aber aufgeben kommt nicht in frage. Welcher Weg ist der richtige?</p>
                    <p>(in Planung)</p>
                </div>
                <div>
                    <h3>Leck mich!</h3>
                    <p>Nachdem er mal wieder seinen Job verloren hat, weiß Torin zunächst nicht, was er machen soll um
                        sich und seine Brüder weiter über Wasser zu halten. Doch dann ergibt sich eine unerwartete
                        Gelegenheit - und kurz darauf noch eine weitere.</p>
                    <p>(in Planung)</p>
                </div>
                <div>
                    <h3>Ich brauch dich nicht!</h3>
                    <p>Remus hat sich sein Leben lang schon ständig um Andere gekümmert. Da lag die Berufswahl als
                        Polizist nicht fern. Aber auch wenn sein Privatleben gerade turbulent genug ist, könnte der Job
                        derzeit nicht langweiliger sein. Da kommt ihm dieser kleine Möchtegerngangster Torin, den er
                        gefühlt sein ganzes Leben lang schon kennt, nur Recht. Der braucht Hilfe um ein paar Dealer
                        loszuwerden und Remus eine Chance sich zu beweisen. Klingt, als ob alle nur dabei gewinnen
                        können. </p>
                    <p>(in Planung)</p>
                </div>
                <div>
                    <h3>Ich komme alleine klar!</h3>
                    <p>Fast sieben Jahre ist es her, dass Remus' Mutter mit einem Mal mit seinem Halbbruder vor der Tür
                        stand. Niemals hätte Remus erwartet, dass er den Kleinen so sehr ins Herz schließen würde. Als
                        er sich die Frage stellen muss, wie weit er bereit ist, für seine Familie zu gehen, gibt es für
                        Remi deshalb zur Abwechslung nur eine mögliche Antwort. </p>
                    <p>(in Planung)</p>
                </div>

                <div>
                    <h3>Der Anfang vom Ende</h3>
                    <p>Es war eine beschissener Tag gewesen. Nein, wohl eher ein beschissener Monat. Erst machten sich
                        die Russen immer weiter in seinem Revier breit, dann fingen sie auch noch an rumzuballern und
                        Leute zu verprügeln. Schlimm genug, dass er einen seiner Gefallen bei Remi einlösen musste.
                        Eigentlich will Torin im Rush-Inn nur etwas saufen und sich jemanden schnappen, den er
                        durchvögeln kann bis zum Morgen. Aber es läuft, wie immer in Torins Leben: Erstens kommt es
                        anders und zweitens als man denkt.</p>
                    <p>(in Planung)</p>
                </div>
            </div> ),
        additionalInfo: "Dieser Band wird aus mehreren kürzeren Geschichten bestehen, die die Geschichte rund um Remus und Torin erzählen.",
        state: STATUS.INTERRUPTED,
        length: "?",
    },
    {
        title: "Schein und Sein",
        series: SERIES.RUSH_INN,
        subSeries: "Limbus",
        subSeriesNo: 1,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.FAMILIE],
        tags: ["MxM", "Young Adult"],
        cover: "limbus1-21_o.jpg",
        content: "Nur ein Fehler, ein einziger Abend in den Ferien, ein paar Drinks zu viel am Lagerfeuer und ein Geständnis, das alles veränderte. Erik ist achtzehn und hat vor drei Wochen sein letztes Schuljahr begonnen. Nachdem Schulrowdy Sandro erfahren hat, dass er schwul ist, gönnt der ihm jedoch keine ruhige Minute mehr. Und als ob das nicht genug wäre, fühlt er sich auch zunehmend von seinem Deutschlehrer schikaniert. Leider ist ihm das alles, nicht so scheiß egal, wie er es gern hätte. Aber vielleicht ist ja Tom, der Student die Lösung. So oder so scheint es bald, dass Erik in seinem letzten Jahr wohl tatsächlich mal nicht nur für die Schule, sondern für das Leben lernt.",
        additionalInfo: (
            <p><strong>Hinweis: </strong> Die Geschichte startet relativ düster, mit Hang zum Dark, entwickelt sich dann
                aber zusammen mit ihrem Protagonisten in eine andere Richtung.</p> ),
        state: STATUS.FINISHED,
        length: "ca. 170.000 Wörter",
    },
    {
        title: "Sieben Tage Hölle",
        series: SERIES.RUSH_INN,
        subSeries: "Limbus",
        subSeriesNo: 2,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.FAMILIE],
        tags: ["MxM", "Young Adult", "New Adult"],
        cover: "limbus2-10_o.jpg",
        content: "Inzwischen sind die Prüfungen geschafft und Erik hat sein Abitur endlich bestanden. Eigentlich sollte er zufrieden sein. Aber noch ist die Schule nicht endgültig für ihn vorbei. Abschlussfahrt, Zeugnisübergabe, Abiball. Die letzte Woche seiner Schulzeit steht bevor und im Grunde möchte Erik nur noch eines: Die Abschlussfahrt genießen und das alles endlich hinter sich bringen. Aber offensichtlich will das Schicksal ihm nicht einmal das gönnen. Statt einer beschaulichen Woche Sonne, Strand und leicht bekleidete Franzosen entwickelt sich der Ausflug immer mehr zum Höllentrip. Und mittendrin der Blödmann Berger, bei dem Erik sich einfach nicht entscheiden kann, ob er ihn lieber um die Ecke oder ins Bett bringen will.",
        additionalInfo: (
            <p><strong>Hinweis: </strong> Im Gegensatz zu anderen Rush-Inn Geschichten muss bei Limbus die Reihenfolge
                beim Lesen zwingend eingehalten werden.</p> ),
        state: STATUS.FINISHED,
        length: "ca. 273.000 Wörter",
    },
    {
        title: "Finden + Suchen",
        series: SERIES.RUSH_INN,
        subSeries: "Limbus",
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.FAMILIE, GENRE.DRAMA],
        tags: ["Düster", "Bonus"],
        cover: "limbus2-5-31_o.jpg",
        content: "Man sagt \"Wer sucht, der findet\". Aber was passiert, wenn das, was man findet, nie das ist, was man sucht?",
        additionalInfo: (
            <div>
                <p>"Suchen + Finden" ist eine Kurzgeschichte, die Roberts Vorgeschichte in Fragmenten erzählt. Sie ist
                    kein Teil der ursprünglichen Planung für die Reihe und ist "spontan" für eine Challenge entstanden,
                    da mir bei der sofort Robert und diese Geschichte in den Sinn kam.</p>
                <p>Es ist sinnvoll sie zwischen Teil 2 und 3 zu lesen.</p>
            </div> ),
        state: STATUS.FINISHED,
        length: "ca. 2000 Wörter",
    },
    {
        title: "Habenichts",
        series: SERIES.RUSH_INN,
        subSeries: "Limbus",
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.DRAMA],
        tags: ["Düster", "Bonus"],
        cover: "limbus2-5-2_o.jpg",
        content: "Der Schulhof ist ein hartes Pflaster, aber Norman muss ja immer noch mal übertreiben...",
        additionalInfo: (
            <div>
                <p>Kurzgeschichte aus der Sixty-Minutes Challenge auf Belletristica.</p>
                <p>Es ist sinnvoll sie zwischen Teil 2 und 3 zu lesen.</p>
            </div> ),
        state: STATUS.FINISHED,
        length: "ca. 1600 Wörter",
    },
    {
        title: "Ich wünschte ...",
        series: SERIES.RUSH_INN,
        subSeries: "Limbus",
        type: BOOK_TYPE.KURZGESCHICHTE,
        genre: [GENRE.DRAMA],
        tags: ["Bonus"],
        cover: "limbus2-5-3_o.jpg",
        content: "Es wäre schön, wenn die Dinge sich ändern würden. Aber was er sich wünscht, ist schwer in Worte zu fassen.",
        additionalInfo: (
            <div>
                <p>Kurzgeschichte aus der Sixty-Minutes Challenge auf Belletristica.</p>
                <p>Es ist sinnvoll sie zwischen den Romanen Teil 2 und 3 zu lesen.</p>
            </div> ),
        state: STATUS.FINISHED,
        length: "ca. 2500 Wörter",
    },
    {
        title: "Aevum",
        series: SERIES.RUSH_INN,
        subSeries: "Limbus",
        subSeriesNo: 6,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.FAMILIE, GENRE.DRAMA],
        tags: ["MxM", "Familie"],
        cover: "limbus3-11_o.jpg",
        content: "Im Grunde läuft es gut zwischen Erik und Rob. Nach den ersten Schmetterlingen " +
            "ergreift sie mehr und mehr der Alltag. Während Erik sein Studium beginnt, hat für Robert das neue Schuljahr angefangen. " +
            "Neue Klasse, neues Glück, alte Probleme. Dazu leider auch jede Menge neue, denn plötzlich taucht Gerald, Roberts älterer " +
            "Halbbruder auf und droht ihn in eine Dunkelheit zurück zu reißen, aus der Rob einst nur knapp entkam. ",
        additionalInfo: "Dritter und letzter Romanteil der Limbus-Reihe.",
        state: STATUS.PLANNED,
        length: "?",
    },
    {
        title: "Schwarzes Herz 1",
        series: SERIES.SCHWARZES_HERZ,
        subSeriesNo: 1,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.FAMILIE, GENRE.DRAMA, GENRE.KRIMI],
        tags: ["MxM", "Familie", "Mafia", "Düster", "Gewalt"],
        cover: "sw-1.jpg",
        content: (
            <div>
                <div><strong>Band 1: Wie alles beginnt</strong></div>
                <div>
                    Jans Leben ist lockerer als es aussieht. Den Job als Callboy macht er seit sechs Jahren. Nicht weil
                    er muss, sondern weil er mit vergleichsweise geringem Einsatz genug Kohle bringt. Als sein
                    kleinkrimineller Pflegebruder Ben verzweifelt an seine Tür klopft, wollte er dem gar nicht erst
                    aufmachen. Aber irgendwie war der Mann schließlich die einzige Form von Familie, die Jan jemals
                    gehabt hatte. Dass er kurz darauf vor diesem verdammten Hotelzimmer stehen würde, um über Bens
                    Wettschulden zu verhandeln, war in Jans Lebensplanung nicht vorgesehen. Genauso wenig wie der
                    Mafioso hinter der Tür, dessen Familie, oder alles, was danach folgte. Hätte er Ben doch die blöde
                    Wohnungstür ins Gesicht geschlagen.
                </div>
            </div>
        ),
        state: STATUS.STARTED,
        length: "Band 1/5",
    },
    {
        title: "Sommerträume",
        series: SERIES.EINZELBAND,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY],
        tags: ["MxM", "Young Adult", "Erste Liebe"],
        cover: "sommert.jpg",
        content: "Gabriel ist 19 und fährt wie jedes Jahr mit seinen Eltern im Sommer nach Italien. Doch im Gegensatz zu früher ist es diesmal kein Urlaub. Sein schwerreicher Vater hat Pläne, welche genau will er nicht verraten. Gabriel ist klar, dass das hier weniger Urlaub als Arbeit werden wird. Bereits an einem der ersten Tage lernt Gabriel jedoch einen jungen Mann kennen, der seine Zeit in Italien doch noch zum Besseren wenden könnte. Aber Gabriel ist schüchtern und der Dorf-Schwarm Giovanni vielleicht nicht unbedingt die beste Wahl, diese Schüchternheit zu überwinden.",
        state: STATUS.STARTED,
        length: "?",
    },
    {
        title: "Elf Jahre und ein Tag",
        series: SERIES.EINZELBAND,
        type: BOOK_TYPE.ROMAN,
        genre: [GENRE.LOVESTORY, GENRE.DRAMA],
        tags: ["MxM", "Young Adult", "New Adult",],
        cover: "elfjahre1-36_o.jpg",
        content: "Sechs Umzüge in den letzten sieben Jahren. Tristan hat aufgehört, sich an Menschen binden zu wollen, Pläne zu machen - was zählt ist die Gegenwart. Dann lernt er Brendan kennen, der genau weiß, wo seine Zukunft hingehen soll. Und David, Brendans besten Freund, der in einer Vergangenheit festhängt, die er nicht loslassen kann - und dem Glauben an das Unmögliche. Drei Freunde auf der Suche nach dem Glück, der Liebe und dem Platz im Leben, wo sie hingehören.",
        state: STATUS.FINISHED,
        length: "ca. 82.000 Wörter",
    }
]

export interface Book {
    title?: string;
    series?: SERIES;
    subSeries?: string;
    subSeriesNo?: number;
    type?: BOOK_TYPE
    author?: string;
    cover?: string;
    genre?: GENRE[];
    tags?: string[];
    state?: STATUS;
    length?: string;
    content?: React.ReactNode;
    additionalInfo?: React.ReactNode;
    links?: string[];
    publishDate?: string;
}