import React from "react";

export const datenschutz = (
    <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
        <h2>I. Name und Anschrift des Verantwortlichen</h2>
        <p>Der Verantwortliche im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der
            Mitgliedsstaaten sowie sonstiger datenschutzrechtlicher Bestimmungen ist :</p>
        <p>Chris Learson<br/>c/o AutorenServices.de<br/>Birkenallee 24<br/>36037 FuldaDeutschland</p>
        <p><em>Pakete und Päckchen werden nicht angenommen.&nbsp;</em></p>
        <p>E-Mail: <a href="mailto:autor@chrislearson.de">autor@chrislearson.de</a><br/>Website:
            www.chrislearson.de</p>
        <h2>II.&nbsp; Name und Anschrift des Datenschutzbeauftragten</h2>
        <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
        <p>&nbsp;<em>(siehe oben)</em></p>
        <h2>III. Allgemeines zur Datenverarbeitung</h2>
        <h3>1. Umfang der Verarbeitung personenbezogener Daten</h3>
        <p>Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer
            funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung
            personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme
            gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht
            möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.</p>
        <h3>2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten</h3>
        <p>Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person
            einholen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a EU-Datenschutzgrundverordnung (DSGVO) als
            Rechtsgrundlage.</p>
        <p>Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei
            die betroffene Person ist, erforderlich ist, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO als
            Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen
            erforderlich sind.</p>
        <p>Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich
            ist, der unser Unternehmen unterliegt, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;c DSGVO als
            Rechtsgrundlage.</p>
        <p>Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person
            eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;d
            DSGVO als Rechtsgrundlage.</p>
        <p>Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten
            erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des Betroffenen das erstgenannte
            Interesse nicht, so dient Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO als Rechtsgrundlage für die
            Verarbeitung.</p>
        <h3>3. Datenlöschung und Speicherdauer</h3>
        <p>Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der
            Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder
            nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der
            Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn
            eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine
            Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung
            besteht.</p>
        <h2>IV. Bereitstellung der Website und Erstellung von Logfiles</h2>
        <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
        <p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen vom
            Computersystem des aufrufenden Rechners.</p>
        <p>Folgende Daten werden hierbei ggf. erhoben:</p>
        <ul>
            <li>Informationen über den Browsertyp und die verwendete Version</li>
            <li>Das Betriebssystem des Nutzers</li>
            <li>Den Internet-Service-Provider des Nutzers</li>
            <li>Die IP-Adresse des Nutzers</li>
            <li>Datum und Uhrzeit des Zugriffs</li>
            <li>Websites, von denen das System des Nutzers auf unsere Internetseite gelangt</li>
            <li>Websites, die vom System des Nutzers über unsere Website aufgerufen werden</li>
        </ul>
        <p>Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten
            zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.</p>
        <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
        <p>Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art.&nbsp;6 Abs.&nbsp;1
            lit.&nbsp;f DSGVO.</p>
        <h3>3. Zweck der Datenverarbeitung</h3>
        <p>Die vorübergehende Speicherung der IP-Adresse durch das System ist notwendig, um eine Auslieferung der
            Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der
            Sitzung gespeichert bleiben.</p>
        <p>Die Speicherung in Logfiles erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. Zudem dienen uns
            die Daten zur Optimierung der Website und zur Sicherstellung der Sicherheit unserer informationstechnischen
            Systeme. Eine Auswertung der Daten zu Marketingzwecken findet in diesem Zusammenhang nicht statt.</p>
        <p>In diesen Zwecken liegt auch unser berechtigtes Interesse an der Datenverarbeitung nach Art.&nbsp;6
            Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
        <h3>4. Dauer der Speicherung</h3>
        <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
            sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige
            Sitzung beendet ist.</p>
        <p>Im Falle der Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine
            darüberhinausgehende Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder
            verfremdet, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.</p>
        <h3>5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
        <p>Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den
            Betrieb der Internetseite zwingend erforderlich. Es besteht folglich seitens des Nutzers keine
            Widerspruchsmöglichkeit.</p>
        <h2>V. Verwendung von Cookies</h2>
        <h3><strong>1. Beschreibung und Umfang der Datenverarbeitung</strong></h3>
        <p>Unsere Webseite verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die im Internetbrowser bzw.
            vom Internetbrowser auf dem Computersystem des Nutzers gespeichert werden. Ruft ein Nutzer eine Website auf,
            so kann ein Cookie auf dem Betriebssystem des Nutzers gespeichert werden. Dieser Cookie enthält eine
            charakteristische Zeichenfolge, die eine eindeutige Identifizierung des Browsers beim erneuten Aufrufen der
            Website ermöglicht.</p>
        <p>Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu gestalten. Einige Elemente unserer
            Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden
            kann.</p>
        <p>In den Cookies werden dabei folgende Daten gespeichert und übermittelt:</p>
        <ul>
            <li>Log-In-Informationen</li>
            <li>Sessioninformationen</li>
            <li>Auswahlen/Bestätigungen, z.B. für das Aktzeptieren von Cookies</li>
        </ul>
        <p>Wir verwenden auf unserer Website darüber hinaus Cookies, die eine Analyse des Surfverhaltens der Nutzer
            ermöglichen.</p>
        <p>Auf diese Weise können folgende Daten übermittelt werden:</p>
        <ul>
            <li>Eingegebene Suchbegriffe</li>
            <li>Häufigkeit von Seitenaufrufen</li>
            <li>Inanspruchnahme von Website-Funktionen</li>
            <li>Bestätigung der Cookiespeicherung</li>
            <li>Sessioninformationen</li>
            <li>Letzter angezeigter Inhalt</li>
        </ul>
        <p>Die auf diese Weise erhobenen Daten der Nutzer werden durch technische Vorkehrungen pseudonymisiert. Daher
            ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam mit
            sonstigen personenbezogenen Daten der Nutzer gespeichert.</p>
        <p>Beim Aufruf unserer Website werden die Nutzer durch einen Infobanner über die Verwendung von Cookies zu
            Analysezwecken informiert und auf jeder Seite im Footer auf diese Datenschutzerklärung verwiesen. Es erfolgt
            in diesem Zusammenhang auch ein Hinweis darauf, wie die Speicherung von Cookies in den Browsereinstellungen
            unterbunden werden kann.</p>
        <h3><strong>2. Rechtsgrundlage für die Datenverarbeitung </strong></h3>
        <p>Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung von Cookies ist Art.&nbsp;6
            Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
        <h3><strong>3. Zweck der Datenverarbeitung</strong></h3>
        <p>Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung von Websites für die Nutzer zu
            vereinfachen. Einige Funktionen unserer Internetseite können ohne den Einsatz von Cookies nicht angeboten
            werden. Für diese ist es erforderlich, dass der Browser auch nach einem Seitenwechsel wiedererkannt
            wird.</p>
        <p>Für folgende Anwendungen benötigen wir Cookies:</p>
        <ul>
            <li>Übernahme von Spracheinstellungen</li>
            <li>Merken von Suchbegriffen</li>
        </ul>
        <p>Die durch technisch notwendige Cookies erhobenen Nutzerdaten werden nicht zur Erstellung von Nutzerprofilen
            verwendet.</p>
        <p>Die Verwendung der Analyse-Cookies erfolgt zu dem Zweck, die Qualität unserer Website und ihre Inhalte zu
            verbessern. Durch die Analyse-Cookies erfahren wir, wie die Website genutzt wird und können so unser Angebot
            stetig optimieren.</p>
        <p>Dies beinhaltet primär die Aufzeichnung des Browserverhaltens und damit eine Analyse der bevorzugt
            aufgerufenen Seiten.</p>
        <p>In diesen Zwecken liegt auch unser berechtigtes Interesse in der Verarbeitung der personenbezogenen Daten
            nach Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO.</p>
        <h3><strong>4. Dauer der Speicherung, Widerspruchs- und Beseitigungsmöglichkeit</strong></h3>
        <p>Cookies werden auf dem Rechner des Nutzers gespeichert und von diesem an unserer Seite übermittelt. Daher
            haben Sie als Nutzer auch die volle Kontrolle über die Verwendung von Cookies. Durch eine Änderung der
            Einstellungen in Ihrem Internetbrowser können Sie die Übertragung von Cookies deaktivieren oder
            einschränken. Bereits gespeicherte Cookies können jederzeit gelöscht werden. Dies kann auch automatisiert
            erfolgen. Werden Cookies für unsere Website deaktiviert, können möglicherweise nicht mehr alle Funktionen
            der Website vollumfänglich genutzt werden.</p>
        <h2>VI.&nbsp;&nbsp;&nbsp;&nbsp; Newsletter</h2>
        <p>Es wird kein Newsletter angeboten.</p>
        <h2>VII.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Registrierung</h2>
        <p>Eine öffentliche Nutzerregistrierung ist aktuell nicht möglich.</p>
        <h2>VIII.&nbsp;&nbsp;&nbsp;&nbsp; Kontaktformular und E-Mail-Kontakt</h2>
        <h3>1. Beschreibung und Umfang der Datenverarbeitung</h3>
        <p>Aktuell ist ausschließlich eine Kontaktaufnahme über die bereitgestellte E-Mail-Adresse möglich. In diesem
            Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.</p>
        <p>Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für
            die Verarbeitung der Konversation verwendet.</p>
        <h3>2. Rechtsgrundlage für die Datenverarbeitung</h3>
        <p>Rechtsgrundlage für die Verarbeitung der Daten ist bei Vorliegen einer Einwilligung des Nutzers Art.&nbsp;6
            Abs.&nbsp;1 lit.&nbsp;a DSGVO.</p>
        <p>Rechtsgrundlage für die Verarbeitung der Daten, die im Zuge einer Übersendung einer E-Mail übermittelt
            werden, ist Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;f DSGVO. Zielt der E-Mail-Kontakt auf den Abschluss eines
            Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b
            DSGVO.</p>
        <h3>3. Zweck der Datenverarbeitung</h3>
        <p>Die Verarbeitung der personenbezogenen Daten aus der Eingabemaske dient uns allein zur Bearbeitung der
            Kontaktaufnahme. Im Falle einer Kontaktaufnahme per E-Mail liegt hieran auch das erforderliche berechtigte
            Interesse an der Verarbeitung der Daten.</p>
        <p>Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten dienen dazu, einen Missbrauch
            des Kontaktformulars zu verhindern und die Sicherheit unserer informationstechnischen Systeme
            sicherzustellen.</p>
        <h3>4. Dauer der Speicherung</h3>
        <p>Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich
            sind. Für die personenbezogenen Daten aus der Eingabemaske des Kontaktformulars und diejenigen, die per
            E-Mail übersandt wurden, ist dies dann der Fall, wenn die jeweilige Konversation mit dem Nutzer beendet ist.
            Beendet ist die Konversation dann, wenn sich aus den Umständen entnehmen lässt, dass der betroffene
            Sachverhalt abschließend geklärt ist.</p>
        <p>Die während des Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer
            Frist von sieben Tagen gelöscht.</p>
        <h3>5. Widerspruchs- und Beseitigungsmöglichkeit</h3>
        <p>Der Nutzer hat jederzeit die Möglichkeit, seine Einwilligung zur Verarbeitung der personenbezogenen Daten zu
            widerrufen. Nimmt der Nutzer per E-Mail Kontakt mit uns auf, so kann er der Speicherung seiner
            personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die Konversation nicht
            fortgeführt werden.</p>
        <p>Ein solcher Widerspruch kann formlos per Email an die Adresse <a href="mailto:admin@chrislearson.de">admin@chrislearson.de</a> geschickt werden.
        </p>
        <p>Alle personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in diesem Fall
            gelöscht.</p>
        <h2>IX. Rechte der betroffenen Person</h2>
        <p>Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen Ihnen
            folgende Rechte gegenüber dem Verantwortlichen zu:</p>
        <h3>1. Auskunftsrecht</h3>
        <p>Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene Daten, die Sie
            betreffen, von uns verarbeitet werden.</p>
        <p>Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende Informationen Auskunft
            verlangen:</p>
        <p>(1) die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
        <p>(2) die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
        <p>(3) die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie betreffenden personenbezogenen
            Daten offengelegt wurden oder noch offengelegt werden;</p>
        <p>(4) die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten oder, falls konkrete
            Angaben hierzu nicht möglich sind, Kriterien für die Festlegung der Speicherdauer;</p>
        <p>(5) das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten,
            eines Rechts auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
            gegen diese Verarbeitung;</p>
        <p>(6) das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
        <p>(7) alle verfügbaren Informationen über die Herkunft der Daten, wenn die personenbezogenen Daten nicht bei
            der betroffenen Person erhoben werden;</p>
        <p>(8) das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß Art.&nbsp;22
            Abs.&nbsp;1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die
            involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für
            die betroffene Person.</p>
        <p>Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden personenbezogenen Daten in
            ein Drittland oder an eine internationale Organisation übermittelt werden. In diesem Zusammenhang können Sie
            verlangen, über die geeigneten Garantien gem. Art.&nbsp;46 DSGVO im Zusammenhang mit der Übermittlung
            unterrichtet zu werden.</p>
        <h3>2. Recht auf Berichtigung</h3>
        <p>Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen, sofern die
            verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder unvollständig sind. Der
            Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.</p>
        <h3>3. Recht auf Einschränkung der Verarbeitung</h3>
        <p>Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie betreffenden
            personenbezogenen Daten verlangen:</p>
        <p>(1) wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer bestreiten, die es dem
            Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen;</p>
        <p>(2) die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten ablehnen und
            stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangen;</p>
        <p>(3) der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigt,
            Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder</p>
        <p>(4) wenn Sie Widerspruch gegen die Verarbeitung gemäß Art.&nbsp;21 Abs.&nbsp;1 DSGVO eingelegt haben und noch
            nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber Ihren Gründen überwiegen.</p>
        <p>Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese Daten – von
            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Union oder eines Mitgliedstaats verarbeitet werden.</p>
        <p>Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie von dem
            Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.</p>
        <h3>4.&nbsp;Recht auf Löschung</h3>
        <h4>a) Löschungspflicht</h4>
        <p>Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich
            gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich zu löschen, sofern einer
            der folgenden Gründe zutrifft:</p>
        <p>(1) Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige
            Weise verarbeitet wurden, nicht mehr notwendig.</p>
        <p>(2) Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a
            oder Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a DSGVO stützte, und es fehlt an einer anderweitigen Rechtsgrundlage
            für die Verarbeitung.</p>
        <p>(3) Sie legen gem. Art.&nbsp;21 Abs.&nbsp;1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine
            vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art.&nbsp;21 Abs.&nbsp;2 DSGVO
            Widerspruch gegen die Verarbeitung ein.</p>
        <p>(4) Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
        <p>(5) Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer rechtlichen
            Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem der Verantwortliche
            unterliegt.</p>
        <p>(6) Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
            Informationsgesellschaft gemäß Art.&nbsp;8 Abs.&nbsp;1 DSGVO erhoben.</p>
        <h4>b) Information an Dritte</h4>
        <p>Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist er gem.
            Art.&nbsp;17 Abs.&nbsp;1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung der
            verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch technischer Art, um für
            die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten verarbeiten, darüber zu informieren,
            dass Sie als betroffene Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von
            Kopien oder Replikationen dieser personenbezogenen Daten verlangt haben.</p>
        <h4>c) Ausnahmen</h4>
        <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
        <p>(1) zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
        <p>(2) zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der Union oder der
            Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur Wahrnehmung einer Aufgabe, die im
            öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen
            übertragen wurde;</p>
        <p>(3) aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß Art.&nbsp;9
            Abs.&nbsp;2 lit.&nbsp;h und i sowie Art.&nbsp;9 Abs.&nbsp;3 DSGVO;</p>
        <p>(4) für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische Forschungszwecke
            oder für statistische Zwecke gem. Art.&nbsp;89 Abs.&nbsp;1 DSGVO, soweit das unter Abschnitt a) genannte
            Recht voraussichtlich die Verwirklichung der Ziele dieser Verarbeitung unmöglich macht oder ernsthaft
            beeinträchtigt, oder</p>
        <p>(5) zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
        <h3>5. Recht auf Unterrichtung</h3>
        <p>Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
            Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie betreffenden
            personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der Daten oder Einschränkung
            der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als unmöglich oder ist mit einem
            unverhältnismäßigen Aufwand verbunden.</p>
        <p>Ihnen steht gegenüber dem Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.</p>
        <h3>6. Recht auf Datenübertragbarkeit</h3>
        <p>Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen
            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Außerdem
            haben Sie das Recht diese Daten einem anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen,
            dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern</p>
        <p>(1) die Verarbeitung auf einer Einwilligung gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;a DSGVO oder Art.&nbsp;9
            Abs.&nbsp;2 lit.&nbsp;a DSGVO oder auf einem Vertrag gem. Art.&nbsp;6 Abs.&nbsp;1 lit.&nbsp;b DSGVO beruht
            und</p>
        <p>(2) die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
        <p>In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
            personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen übermittelt werden,
            soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen dürfen hierdurch nicht
            beeinträchtigt werden.</p>
        <p>Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die für die
            Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung
            öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.</p>
        <h3>7. Widerspruchsrecht</h3>
        <p>Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art.&nbsp;6 Abs.&nbsp;1
            lit.&nbsp;e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen
            gestütztes Profiling.</p>
        <p>Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei denn, er kann
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
            überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen.</p>
        <p>Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das
            Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
            derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
            Verbindung steht.</p>
        <p>Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
            personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.</p>
        <p>Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft –
            ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben,
            bei denen technische Spezifikationen verwendet werden.</p>
        <h3>8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</h3>
        <p>Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen. Durch den
            Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten
            Verarbeitung nicht berührt.</p>
        <h3>9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling</h3>
        <p>Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich
            Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet
            oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt nicht, wenn die Entscheidung</p>
        <p>(1) für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem Verantwortlichen erforderlich
            ist,</p>
        <p>(2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt,
            zulässig ist und diese Rechtsvorschriften angemessene Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten
            sowie Ihrer berechtigten Interessen enthalten oder</p>
        <p>(3) mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
        <p>Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten nach
            Art.&nbsp;9 Abs.&nbsp;1 DSGVO beruhen, sofern nicht Art.&nbsp;9 Abs.&nbsp;2 lit.&nbsp;a oder g DSGVO gilt
            und angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen getroffen
            wurden.</p>
        <p>Hinsichtlich der in (1) und (3) genannten Fälle trifft der Verantwortliche angemessene Maßnahmen, um die
            Rechte und Freiheiten sowie Ihre berechtigten Interessen zu wahren, wozu mindestens das Recht auf Erwirkung
            des Eingreifens einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
            Anfechtung der Entscheidung gehört.</p>
        <h2>10. Recht auf Beschwerde bei einer Aufsichtsbehörde</h2>
        <p>Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht Ihnen das Recht
            auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
            Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.</p>
        <p>Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den Beschwerdeführer über den
            Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit eines gerichtlichen Rechtsbehelfs
            nach Art.&nbsp;78 DSGVO.</p>
    </div>
)