import React from "react";
import { Breadcrumb, Card, Collapse } from "antd";
import { Typography } from "@mui/material";
import { datenschutz } from "../data/datenschutz";
import { defaultBcPath } from "../utils/config";
import { MailOutlined } from "@ant-design/icons";

const Contact = () => {

    const localPath = defaultBcPath.concat(
        {
            title: (<span><MailOutlined /> Impressum</span>)
        }
    )

    return (
        <div className="site-layout-content">
            <Breadcrumb items={localPath} />
            <h1>Impressum</h1>
            <h3>Angaben gemäß § 5 TMG</h3>
            <div style={{ display: "flex", flexDirection: "row", verticalAlign: "bottom" }}>
                <Card
                    title={"Anschrift"}
                    size={"small"}
                    style={{ maxWidth: "400px", flexGrow: 1, marginRight: "20px" }}>
                    Chris Learson<br/>
                    c/o AutorenServices.de<br/>
                    Birkenallee 24<br/>
                    36037 Fulda
                </Card>

                <Typography variant={"caption"} style={{ textAlign: "center", verticalAlign: "bottom" }}>Keine Annahme
                    von
                    Päckchen oder Paketen. <br/>Bitte bevorzugt per Email
                    Kontakt aufnehmen.<br/>Danke!</Typography>
            </div>

            <div style={{ marginTop: "20px" }}>
                Kontakt<br/>
                E-Mail: <a href={"mailto:autor@chrislearson.de"}>autor@chrislearson.de</a>
            </div>
            <Typography variant={"body1"}>Diese Seite verwendet keine Cookies zur Datenspeicherung.</Typography>
            <h3>Verbraucherstreitbeilegung/Universalschlichtungsstelle</h3>
            <Typography variant={"body1"}>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
                einer Verbraucherschlichtungsstelle teilzunehmen.</Typography>

            <Collapse
                items={[{
                    key: '1', label: 'Datenschutzerklärung', children:
                        <div>
                            {datenschutz}
                        </div>
                }]}
            />
        </div>
    )
};

export default Contact;