import React from "react";
import "../theme/landing.scss";
import { Col, Row } from "antd";


const Landing: React.FC = () => {
    return (
        <div id={"landing"}>
            <Row>
                <Col span={24}>
                    <img src={"/logo/logoCL1100.jpg"} style={{ maxWidth: "100%", margin: "5px" }}
                         alt={"Chris Learson - Spinning Love Stories Since 2001"}/>
                </Col>
            </Row>
            <Row>
                <div className={"landingText"}>
                    Meine Geschichten kann man aktuell auf <a href="https://belletristica.com/de/users/7525-ken"
                                                              target="_blank" rel="noreferrer">Belletristica</a> und <a
                    href="https://www.fanfiktion.de/u/Ken" target="_blank" rel="noreferrer">Fanfiktion.de</a> finden. Letzteres ist
                    allerdings
                    eher als statisches Archiv zu betrachten. Aufgrund diverser Vorkommnisse bin ich aktuell nicht
                    sicher,
                    ob ich dort noch weiter veröffentlichen möchte. Bereits vorhandene Texte habe ich derzeit aber nicht
                    vor
                    zu löschen. Die vollständige Liste meiner Geschichten befindet sich aktuell auf Bellestristica. Wo
                    meine
                    Sachen nach der Schließung von Belle Ende 2024 hinwandern werden, weiß ich noch nicht.
                </div>
            </Row>
        </div>
    );
};

export default Landing;