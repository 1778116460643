import { MenuOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Menu, MenuProps, Row } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../theme/DesktopHeader.scss";


export function DesktopHeader() {

    const menuItems: MenuProps['items'] = [
        {
            key: '1',
            label: ( <Link to="/">Start</Link> ),
        },
        {
            key: '2',
            label: ( <Link to="/books">Bücher</Link> ),
            children: [
                {
                    key: "2-1",
                    label: ( <Link to="/books/rushInn">Rush-Inn</Link> )
                },
                {
                    key: "2-2",
                    label: ( <Link to="/books/sw">Schwarzes Herz</Link> )
                },
                {
                    key: "2-3",
                    label: ( <Link to="/books/single">Einzelbände</Link> )
                }
            ]
        },
        {
            key: '3',
            label: ( <Link to="/info">Infos</Link> ),
        },
        {
            key: '4',
            label: ( <Link to="/contact">Kontakt</Link> ),
        }
    ]
    return (
        <div className={"desktopTopMenu"}>
            <Row style={{ height: "100%", width: "100%" }}>
                <Col span={8} style={{ height: "100%" }}>
                    <Link to={"/"}><img src={"/logo/logoCL150red.jpg"} alt={"Chris Learson"}
                                        style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            textAlign: "center",
                                            verticalAlign: "middle"
                                        }}/></Link>
                </Col>
                <Col span={16} style={{ height: "100%" }}>
                    <Menu items={menuItems} mode={"horizontal"} className={"topMenu"}/>
                </Col>
            </Row>
        </div>
    )
}

export function MobileHeader() {
    const [menuVisible, setMenuVisible] = useState(false);

    const menuItems: MenuProps['items'] = [
        {
            key: '1',
            type: "group",
            label: ( <Link to="/">Start</Link> ),
        },
        {
            key: '2',
            type: "group",
            label: ( <Link to="/books">Bücher</Link> ),
            children: [
                {
                    key: "2-1",
                    label: ( <Link to="/books/rushInn">Rush-Inn</Link> )
                },
                {
                    key: "2-2",
                    label: ( <Link to="/books/sw">Schwarzes Herz</Link> )
                },
                {
                    key: "2-3",
                    label: ( <Link to="/books/single">Einzelbände</Link> )
                }
            ]
        },
        {
            key: '3',
            type: "group",
            label: ( <Link to="/info">Infos</Link> ),
        },
        {
            key: '4',
            type: "group",
            label: ( <Link to="/contact">Kontakt</Link> ),
        }
    ]
    const showDrawer = () => {
        setMenuVisible(true);
    };

    const onClose = () => {
        setMenuVisible(false);
    };

    const handleMenuItemClick = () => {
        onClose(); // Close the drawer when a menu item is clicked
    };


    return (
        <div className={"mobileTopMenu"}>
            <Row style={{ height: "100%", width: "100%" }}>
                <Col span={12} style={{ height: "100%" }}>
                    <Link to={"/"}>
                        <img src={"/logo/logoCL150red.jpg"} alt={"Chris Learson"}
                             style={{
                                 maxWidth: "100%",
                                 maxHeight: "100%",
                                 textAlign: "center",
                                 verticalAlign: "middle"
                             }}/></Link>
                </Col>
                <Col span={12} style={{ height: "100%" }}>
                    <div style={{ width: "100%", textAlign: "right" }}>
                        <Button type="primary" onClick={showDrawer} icon={<MenuOutlined/>} size={"large"}/>
                    </div>
                    <Drawer
                        title="Menü"
                        placement="left"
                        closable={true}
                        onClose={onClose}
                        visible={menuVisible}
                        width={300}
                    >
                        <Menu
                            items={menuItems}
                            mode={"inline"}
                            defaultOpenKeys={['2']}
                            forceSubMenuRender={true}
                            inlineCollapsed={false}
                            onClick={() => handleMenuItemClick()}/>
                    </Drawer>
                </Col>
            </Row>
        </div>
    )
}