import React, { useState } from "react";
import { Anchor, Breadcrumb, Button, Drawer } from "antd";
import { defaultBcPath } from "../utils/config";
import { InfoCircleOutlined } from "@ant-design/icons";

const Info = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    const localPath = defaultBcPath.concat(
        {
            title: ( <span><InfoCircleOutlined/> Informationen</span> )
        }
    )

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const onClose = () => {
        setDrawerVisible(false);
    };

    return (
        <div className="site-layout-content">
            <Breadcrumb items={localPath}/>
            <div style={{width: "100%", textAlign: "right"}}>
                <Button type="default" onClick={showDrawer}>Inhalt</Button>
            </div>
            <Drawer
                title="Inhalt"
                placement="right"
                closable={true}
                onClose={onClose}
                visible={drawerVisible}
                width={200}

            >
                <Anchor
                    direction="vertical"
                    items={[
                        {
                            key: 'top',
                            href: '#root',
                            title: 'Nach oben',
                        },
                        {
                            key: 'aboutMe',
                            href: '#aboutMe',
                            title: 'Über mich - in aller Kürze',
                        },
                        {
                            key: 'rushInn',
                            href: '#rushInn',
                            title: 'Über das Rush-Inn',
                            children: [
                                {
                                    key: 'rushInn-ICU',
                                    href: '#rushInn-ICU',
                                    title: 'ICU - I see you',
                                },
                                {
                                    key: 'rushInn-SG',
                                    href: '#rushInn-SG',
                                    title: 'Stadtgeflüster',
                                },
                                {
                                    key: 'rushInn-Limbus',
                                    href: '#rushInn-Limbus',
                                    title: 'Limbus',
                                }
                            ]
                        }
                    ]}
                />
            </Drawer>
            <div id={"infoContent"}>
                <h2 id={"aboutMe"}>Über mich - in aller Kürze</h2>
                <p>Ich bin ein Kind der 70er Jahre und veröffentliche seit ca. 2001 meine Geschichten im Internet.
                    Früher unter anderer Adresse, heute hier. Geschichten sind meine Leidenschaft, auch wenn sie mir
                    mitunter durchaus so einiges an Leid erschaffen.</p>
                <p>Leider komme ich seit ca. 2023 nicht mehr so oft zum Schreiben, wie ich es gerne hätte. In
                    kleinen
                    Schritten geht es denoch voran. Bei allem anderen bitte ich um Geduld.</p>
                <p>Meine Geschichten kann man aktuell auf Belletristica und Fanfiction.de finden.
                    Letzteres ist allerdings eher als statisches Archiv zu betrachten. Aufgrund diverser
                    Vorkommnisse
                    bin ich aktuell nicht sicher, ob ich dort noch weiter veröffentlichen möchte. Bereits vorhandene
                    Texte habe ich derzeit aber nicht vor zu löschen.
                    Die vollständige Liste meiner Geschichten befindet sich aktuell auf Bellestristica. Wo meine
                    Sachen
                    nach der Schließung von Belle Ende 2024 hinwandern werden, weiß ich noch nicht.</p>

                <h2 id={"rushInn"}>Über das Rush-Inn</h2>
                <p>Das Rush-Inn ist der zentrale Mittelpunkt einer inzwischen recht zahlreichen Reihe von
                    Geschichten.
                    Die kleine, eigentlich unscheinbare Kneipe befindet sich in einer nicht nähe bezeichneten
                    deutschen
                    Großstadt und ist in dieser für die dortige schwule Szene ein bekannter Treffpunkt.</p>
                <p>Inhaber ist Alexander, ein junger Mann, dessen Alter im Verlauf der Geschichten zwischen etwa 27
                    und
                    32 variieren dürfte, jedoch nie näher beziffert wird. Er selbst ist im Gegensatz zu seinen
                    Gästen
                    nicht schwul, sondern dauer-verlobt mit seiner großen Liebe Isabella. Wie genau es dazu kam,
                    dass er
                    in seinem jungen Alter der Besitzer dieser kleinen Kneipe mit seinem auserlesenen Besucherkreis
                    wurde, ist bisher ein wohl gehütetes Geheimnis. </p>

                <h3 id={"rushInn-ICU"}>ICU - I see you</h3>
                <p>Die Reihe beinhaltet Storys in denen Ärzte und/oder Patienten und ihre Geschichten
                    vorkommen.<br/>
                    Aktuell sind drei Bände in dieser Reihe geplant:</p>

                <ul>
                    <li>Blindes Vertrauen (Paul & Alan) - Romance</li>
                    <li>Alles auf Sieg (Rick & André) - Romance</li>
                    <li>Blinde Wut (Alan, André) - Freundschaft</li>
                </ul>
                <p>Die obige Reihenfolge entspricht der Nummerierung in der Reihe. Die Chronologie wäre genau
                    umegkehrt.
                    Die Bände sind (bewusst) in der chronologisch "falschen" Reihenfolge geschrieben.</p>
                <p>Die folgenden Charakter haben hier ihre Stammgeschichten: Paul Feldmann, Richard "Ricky" Hansen,
                    Dr.
                    Alan Koch, Dr. André Clavier.</p>
                <p>Alles auf Sieg pausiert aktuell. Mir fehlen Muse und Motivation, es zu beenden.</p>

                <h3 id={"rushInn-SG"}>Stadtgeflüster</h3>
                <p>Die Storys aus Stadtgeflüster beinhalten alle einen gewissen Anteil an Verbrechen und
                    Kriminalität.
                    Teilweise durch die Beteiligten selbst, teilweise durch widrige Umstände.</p>
                <p>Die Teile sind chronologisch angeordnet und sollten auch in dieser Reihenfolge gelesen werden.
                    Aktuell sind 5 Bände fertig oder in Planung, eventuell folgt ein 6. Band.</p>
                <p>Die folgenden Charaktere haben in diese Reihe ihre Stammgeschichten: Frederik Peters, Lance
                    Morgan,
                    Remus Winter, Torin Prerov und weitere Charaktere in Nebengeschichten.</p>

                <h3 id={"rushInn-Limbus"}>Limbus</h3>
                <p>Die Limbus-Reihe dreht sich ausschließlich um Erik Hoffmann. Einen jungen Mann, der zu Beginn der
                    Reihe in sein letztes Schuljahr startet. Die Reihe begleitet ihn über einen Zeitraum von ca. 2
                    Jahre
                    (eventuell bis zu 4) in denen er versucht herauszufinden was er will - und von wem.</p>
                <p>Weitere zentrale Charaktere sind Thomas "Tom" Gerner, sowie "Herr R. Berger".</p>
            </div>

        </div>
    )
};

export default Info;