import React from 'react';
import { HashRouter as Router, Link, Route, Routes } from 'react-router-dom';
import { ConfigProvider, FloatButton, Layout } from 'antd';
import Home from "./routes/Home";
import Info from "./routes/Info";
import Contact from "./routes/Contact";
import Books from "./routes/Books";
import BookDetails from "./components/bookComponents/BookDetails";
import darkTheme from "./theme/darktheme";
import "./theme/main.scss";
import "./theme/nav.scss";
import Portfolio from "./components/bookComponents/Portfolio";
import { books, SERIES } from "./data/books";
import { DesktopHeader, MobileHeader } from "./components/layoutComponents/HeaderComponents";

const { Header, Content, Footer } = Layout;

function App() {
    return (
        <ConfigProvider theme={darkTheme}>
            <Router>
                <Layout className="layout">
                    <Header className={"customHeader"}>
                        <DesktopHeader/>
                        <MobileHeader/>
                    </Header>
                    <Content className={"myContent"}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/info" element={<Info/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/books" element={<Books/>}/>
                            <Route path="/books/rushInn" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.RUSH_INN}/>
                            }/>
                            <Route path="/books/rushInn/sg" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.RUSH_INN}
                                    preselectedSubSeries={"Stadtgeflüster"}/>
                            }/>
                            <Route path="/books/rushInn/icu" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.RUSH_INN}
                                    preselectedSubSeries={"ICU - I see you"}/>
                            }/>
                            <Route path="/books/rushInn/limbus" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.RUSH_INN}
                                    preselectedSubSeries={"Limbus"}/>}/>
                            <Route path="/books/rushInn/single" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.RUSH_INN}
                                    preselectedSubSeries={"Einzelbände"}/>}/>
                            <Route path="/books/sw" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.SCHWARZES_HERZ}/>}/>
                            <Route path="/books/single" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.EINZELBAND}/>}/>
                            <Route path="/books/belle" element={
                                <Portfolio
                                    books={books}
                                    preselectedSeries={SERIES.BELLETRISTICA}/>}/>
                            <Route path="/books/:index" element={
                                <BookDetails/>
                            }/>
                        </Routes>
                        <FloatButtonWrapper/>
                    </Content>

                    <Footer className={"footer"}>
                        <div>
                            <img
                                src={"/logo/logoCL152.png"}
                                width={45}
                                height={45}
                                alt={"ChrisLearson.de"}
                                style={{ margin: "auto", verticalAlign: "middle" }}
                            />©{new Date().getFullYear()} Created by Chris Learson - <Link
                            to="/contact">Datenschutz</Link>

                        </div>
                    </Footer>

                </Layout>
            </Router>
        </ConfigProvider>
    );
}

const FloatButtonWrapper = () => {
    return (
        <div className="contentWidth" style={{ position: 'relative' }}>
            <FloatButton.BackTop shape="square" type="default" description={"HOCH"} className={"customBackTop"}/>
        </div>
    );
};


export default App;
