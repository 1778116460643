import React, { ReactNode } from 'react';
import './Paper.scss'; // Import your CSS file for custom styling

interface PaperProps {
    title?: string;
    children: ReactNode;
}

const Paper: React.FC<PaperProps> = ({ title, children }) => {
    return (
        <div className="paper">

            <fieldset>
                {title && (<legend>{title}</legend>)}
                {children}
            </fieldset>
        </div>
    );
};

export default Paper;
