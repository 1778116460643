import React from "react";
import Landing from "../components/Landing";
import "../theme/landing.scss";
import { Breadcrumb } from "antd";
import { defaultBcPath } from "../utils/config";

const Home = () => {

    return (
        <div>
            {/*<Breadcrumb items={defaultBcPath}/>*/}
            <Landing/>
        </div>
    )
};

export default Home;
