import { ThemeConfig } from "antd";

const darkTheme: ThemeConfig = {
    "token": {
        "colorTextBase": "#cccccc",
        "colorPrimary": "#faad14",
        "wireframe": true,
        "fontSize": 16,
        "colorInfo": "#faad14",
        "colorBgBase": "#000000",
        "colorWarning": "#eddd6b",
        "colorError": "#e28065",
        "colorSuccess": "#91c179"
    },
    "components": {
        "Layout": {
            "headerColor": "rgb(250, 173, 20)",
            "bodyBg": "rgb(0, 0, 0)",
            "footerBg": "rgb(51,10,0)",
            "headerBg": "rgb(51,10,0)",
            "headerHeight": 110
        },
        "Card": {
            "colorBgContainer": "rgb(34, 34, 34)",
            "colorTextHeading": "rgb(250, 173, 20)",
            "colorFillAlter": "rgb(70, 64, 61)",
            "colorBorderSecondary": "rgb(91, 89, 89)"
        },
        "Divider": {
            "colorText": "rgb(250, 173, 20)",
            "colorSplit": "rgb(250, 173, 20)"
        },
        "Typography": {
            "colorLinkHover": "rgb(130, 250, 250)",
            "colorLinkActive": "rgb(212, 108, 6)",
            "titleMarginTop": "1.5em",
            "titleMarginBottom": "0.2em",
            "fontSizeHeading1": 34,
            "fontSizeHeading2": 28,
            "fontSizeHeading3": 22,
            "fontSizeHeading5": 16,
            "lineHeight": 1.2,
            "marginXS": 6,
            "marginXXS": 3
        },
        "Button": {
            "defaultBg": "rgb(34,34,34)",
            "defaultBorderColor": "rgba(94,94,94,0.73)",
            "colorBorderSecondary": "rgba(94,94,94,0.73)",
            "colorPrimaryHover": "rgb(132, 218, 113)",
            "borderRadius": 4,
            "colorTextDisabled": "rgba(186, 186, 186, 0.3)",
            "colorPrimary": "rgb(212, 108, 6)",
        },
        "Menu": {
            "itemHoverBg": "rgba(204, 204, 204, 0.2)",
            "darkItemHoverBg": "rgba(204, 204, 204, 0.2)",
            "darkGroupTitleColor": "rgba(250, 173, 20, 0.2)",
            "darkItemDisabledColor": "rgba(34, 34, 34, 0.1)",
            "colorFillAlter": "rgba(34, 34, 34, 0.6)",
            "colorSplit": "rgba(34, 34, 34, 0.6)",
            "itemSelectedBg": "rgb(250, 173, 20)",
            "itemSelectedColor": "rgb(34, 34, 34)",
            "colorBgLayout": "rgb(51,10,0)",
            "colorBgContainer": "rgb(51,10,0)",
            "fontSize": 21
        },
        "Select": {
            "colorBgContainer": "rgba(0, 0, 0, 0.48)",
            "optionSelectedColor": "rgb(34, 34, 34)",
            "optionActiveBg": "rgb(34, 34, 34)",
            "colorBgElevated": "rgb(62, 62, 62)",
            "colorBorder": "rgb(0, 0, 0)",
            "colorText": "rgb(250, 173, 20)",
            "colorTextDescription": "rgba(204, 204, 204, 0.45)",
            "optionSelectedBg": "rgba(250, 173, 20, 0.38)",
            "selectorBg": "rgb(80, 79, 79)",
            "controlOutline": "rgb(250, 173, 20)"
        }
    }
}
export default darkTheme;
