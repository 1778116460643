import React from "react";
import { SERIES, STATUS } from "../../data/books";
import {
    BookOutlined,
    CheckOutlined,
    EditOutlined,
    FileUnknownOutlined,
    StopOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import { Button, Select } from "antd";
import "../../theme/Portfolio.scss";
import Paper from "../utilsComponents/Paper";
import { DefaultOptionType } from "antd/es/select";

interface SeriesFilterProps {
    seriesOptions: SERIES[] | undefined;
    subSeriesOptions: string[];
    selectedSeries: string | null;
    selectedSubSeries: string | null;
    setSelectedSeries: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedSubSeries: React.Dispatch<React.SetStateAction<string | null>>;
    clearSeriesFilter: () => void;
}

const SeriesFilter: React.FC<SeriesFilterProps> = ({
                                                       seriesOptions,
                                                       subSeriesOptions,
                                                       selectedSeries,
                                                       selectedSubSeries,
                                                       setSelectedSeries,
                                                       setSelectedSubSeries,
                                                       clearSeriesFilter
                                                   }) => {
    const getSeriesOptions = (): DefaultOptionType[] => {
        const all: DefaultOptionType[] = [{ label: "Alle", value: null }];
        if (seriesOptions) {
            return all.concat(seriesOptions.map(o => {
                return { value: o.toString(), label: o.toString() }
            }))
        } else {
            return all;
        }
    }

    const getSubSeriesOptions = (): DefaultOptionType[] => {
        const all: DefaultOptionType[] = [{ label: "Alle", value: null }];
        if (subSeriesOptions) {
            return all.concat(subSeriesOptions.map(o => {
                return { value: o.toString(), label: o.toString() }
            }))
        } else {
            return all;
        }
    }
    const subSeriesButtonRender = () => {

        return(
            <Select
                variant={"outlined"}
                options={getSubSeriesOptions()}
                title={"Reihe wählen"}
                placeholder={"Alle"}
                defaultValue={selectedSubSeries}
                value={selectedSubSeries}
                onChange={(value) => {
                setSelectedSubSeries(selectedSubSeries === value ? null : value);
            }}
                    style={{ width: '100%', minWidth: "200px" }}
                    placement={"bottomLeft"}
                    dropdownStyle={{ minWidth: "fit-content" }}
            />
        )
    }

    return (
        <>
            <Paper title={"Serienfilter"}>
                <Select variant={"outlined"}
                        options={getSeriesOptions()}
                        title={"Serie wählen"}
                        placeholder={"Alle"}
                        defaultValue={selectedSeries}
                        value={selectedSeries}
                        onChange={(value) => {
                        setSelectedSeries(selectedSeries === value ? null : value)
                        setSelectedSubSeries(null)
                }}
                        style={{ width: '100%', minWidth: "200px" }}
                        placement={"bottomLeft"}
                        dropdownStyle={{ minWidth: "fit-content" }}
                />
            </Paper>

            {selectedSeries && subSeriesOptions.length > 1 && (
                <Paper title={"Reihenfilter"}>
                    {subSeriesButtonRender()}
                </Paper>
            )
            }
        </>
    );
};

interface StatusFilterProps {
    statusOptions: STATUS[];
    selectedState: STATUS | null;
    setSelectedState: React.Dispatch<React.SetStateAction<STATUS | null>>;
    clearStateFilter: () => void;
    setSelectedSeries: React.Dispatch<React.SetStateAction<string | null>>;
    setSelectedSubSeries: React.Dispatch<React.SetStateAction<string | null>>;
}

const StatusFilter: React.FC<StatusFilterProps> = ({
                                                       statusOptions,
                                                       selectedState,
                                                       setSelectedState,
                                                       clearStateFilter,
                                                       setSelectedSubSeries, setSelectedSeries
                                                   }) => {
    const getIconForStatus = (state: STATUS) => {
        switch (state) {
            case STATUS.PLANNED:
                return ( <span><UnorderedListOutlined title={state}/> {state.toString()}</span> )
            case STATUS.STARTED:
                return ( <span><EditOutlined title={state}/> {state.toString()}</span> )
            case STATUS.INTERRUPTED:
                return ( <span><StopOutlined title={state}/> {state.toString()}</span> )
            case STATUS.FINISHED:
                return ( <span><CheckOutlined title={state}/> {state.toString()}</span> )
            case STATUS.PUBLISHED:
                return ( <span><BookOutlined title={state}/> {state.toString()}</span> )
            default:
                return <FileUnknownOutlined title={state}/>
        }
    };

    const getOptions = (): DefaultOptionType[] => {
        const all: DefaultOptionType[] = [{ label: "Alle", value: null }];
        return all.concat(statusOptions.map(o => {
            return { value: o.toString(), label: getIconForStatus(o) }
        }))
    }

    return (
        <Paper title={"Statusfilter"}>
            <Select variant={"outlined"} options={getOptions()} title={"StatusFilter"} placeholder={"Alle"}
                    defaultValue={null} onChange={(value) => {
                setSelectedState(selectedState === value ? null : value);
                setSelectedSeries(null);
                setSelectedSubSeries(null);
            }}
                    style={{ width: '100%', minWidth: "200px" }}
                    placement={"bottomLeft"}
                    dropdownStyle={{ minWidth: "fit-content" }}
            />
        </Paper>
    );
};

export { SeriesFilter, StatusFilter };
