import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getRoutePathForSeries, getURLSafeString } from "../../utils/stringUtils";
import { Breadcrumb, Card, Image, Tag } from "antd";
import { Book, books, SERIES, STATUS } from "../../data/books";
import { coverPath, defaultBcPath } from "../../utils/config";
import {
    BookOutlined,
    CheckOutlined,
    EditOutlined,
    FileUnknownOutlined,
    StopOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import "../../theme/BookDetails.scss";

const BookDetails: React.FC = () => {
    const { index } = useParams<{ index: string }>();
    const path = index ? index.split("-") : [];
    const [series, subSeries, subSeriesNo, title] = path;
    const [bookPath, setBookPath] = useState<BreadcrumbItemType[]>();
    const [book, setBook] = useState<Book>();

    useEffect(() => {
        const localBook: Book | undefined = books.find(b => {
            // Initialize the search criteria object
            const searchCriteria: Partial<Book> = {};

            // Add properties to the search criteria if they exist in the URL path
            if (series) searchCriteria.series = series as SERIES;
            if (subSeries) searchCriteria.subSeries = subSeries;
            if (subSeriesNo) searchCriteria.subSeriesNo = parseInt(subSeriesNo);
            if (title) searchCriteria.title = title;

            // Compare the search criteria with the properties of each book
            return Object.keys(searchCriteria).every(key => {
                const bookValue = b[key as keyof Book];
                const searchValue = searchCriteria[key as keyof Book];
                return getURLSafeString(bookValue?.toString() || "") === searchValue?.toString();
            });
        });
        setBook(localBook);
    }, [series, subSeries, subSeriesNo, title])

    useEffect(() => {
        let path = defaultBcPath.concat({ title: ( <Link to={"/books"}><BookOutlined/> Bücher</Link> ) });
        if (book?.series) {
            path = path.concat({ title: ( <Link to={getRoutePathForSeries(book.series)}> {book.series}</Link> ) })
        }
        if (book?.subSeries) {
            path = path.concat({
                title: ( <Link to={getRoutePathForSeries(book.series, book.subSeries)}> {book.subSeries}</Link> )
            })
        }
        if (book?.title) {
            path = path.concat({ title: book?.title });
        }
        setBookPath(path);
    }, [book])


    if (!book) {
        return <div>Book not found</div>;
    }

    const tagRender = (tags: string[]) => {
        const internalRender = tags.map((t, index) => {
            const intKey = "tag_" + index;
            return ( <Tag bordered={true} key={intKey}>{t}</Tag> )
        })
        return ( tags && tags.length > 0 ) ? (
            <div style={{ marginBottom: "10px" }}>{book.tags && (
                <div id={"tags"}>{internalRender}</div> )}
            </div> ) : ( <></> )
    }

    const statusIcon = (status?: STATUS) => {
        switch (status) {
            case STATUS.PLANNED:
                return <UnorderedListOutlined title={status} className={"stateIcon"}/>
            case STATUS.STARTED:
                return <EditOutlined title={status} className={"stateIcon"}/>
            case STATUS.INTERRUPTED:
                return <StopOutlined title={status} className={"stateIcon"}/>
            case STATUS.FINISHED:
                return <CheckOutlined title={status} className={"stateIcon"}/>
            case STATUS.PUBLISHED:
                return <BookOutlined title={status} className={"stateIcon"}/>
            default:
                return <FileUnknownOutlined className={"stateIcon"} title={"Status unbekannt"}/>
        }
    }

    const renderTitle = (
        <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}><h1>{book.title}</h1></div>
            <div id={"headlineIcon"} >{statusIcon(book.state)}</div>
        </div>
    );

    const seriesRender = (
        <div><strong>Serie: </strong>
            {book.series && ( <>{book.series}</> )}
            {book.subSeries && ( <>{" / " + book.subSeries}</> )}
            {book.subSeriesNo && ( <>{" " + book.subSeriesNo}</> )}
        </div>
    )

    const genreRender = (
        <div id={"genre"} style={{ marginTop: "10px" }}>
            <strong>Genre:</strong> {( book && book.genre ) ? book.genre.join(", ") : "-"}
        </div> )

    const lengthRender = (
        <div id={"length"} style={{ marginTop: "10px" }}>
            <strong>Länge:</strong> {book.length ?? "-"}
        </div> )

    const stateRender = (
        <div id={"status"} style={{ marginTop: "10px" }}>
            <strong>Status:</strong> {statusIcon(book.state)} {book.state}
        </div> )

    const desktopRender = (
        <div style={{ display: "flex", marginTop: "20px" }} id={"headInfo"}>
            <Card
                id={"info"}
                title={renderTitle}
                style={{ width: "100%" }}
            >
                <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: 1, marginRight: "20px" }}>
                        {tagRender(book.tags ?? [])}
                        {seriesRender}
                        {book.genre && genreRender}
                        {book.length && lengthRender}
                        {book.state && stateRender}
                        {book.content && ( <div id={"content"} style={{ marginTop: "10px" }}>{book.content}</div> )}
                        {book.additionalInfo && (
                            <div id={"addInfo"} style={{ marginTop: "10px" }}>{book.additionalInfo}</div> )}
                    </div>
                    <div style={{ flexGrow: 1, textAlign: "end", alignItems: "end" }}>
                        <Image
                            title={book.title}
                            src={`${coverPath}${book.cover}`}
                            width={200}
                        />
                    </div>
                </div>
            </Card>
        </div>
    )

    const mobileRender = (
        <div style={{ display: "flex", marginTop: "20px" }} id={"headInfo"}>

            <Card title={<h2 style={{ textAlign: "center" }}>{book.title}</h2>} style={{ width: "100%" }}>
                <div style={{ justifyContent: "center", textAlign: "center" }}>
                    {tagRender(book.tags ?? [])}
                    <Image
                        title={book.title}
                        src={`${coverPath}${book.cover}`}
                        width={"100%"}
                        style={{ maxHeight: "300px", objectFit: "contain" }}
                    />
                    {seriesRender}
                    {book.genre && genreRender}
                    {book.length && lengthRender}
                    {book.state && stateRender}
                    {book.content && ( <div id={"content"} style={{ marginTop: "10px" }}>{book.content}</div> )}
                    {book.additionalInfo && (
                        <div id={"addInfo"} style={{ marginTop: "10px" }}>{book.additionalInfo}</div> )}
                </div>
            </Card>

        </div>
    );

    return (
        <div className="bookDetails">
            <Breadcrumb items={bookPath}/>
            <div className={"desktopDetails"}>{desktopRender}</div>
            <div className={"mobileDetails"}>{mobileRender}</div>
        </div>
    );
};

export default BookDetails;
